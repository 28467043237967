<template>
    <div class="card">
        <div class="card-thumb">
            <img :src="image" alt="Normandie Mérule" />
        </div>
        <div class="card-body">
            <h4 class="card-subtitle">{{ subtitle }}</h4>
            <h2 class="card-title">{{ title }}</h2>
            <p class="card-text">{{ desc }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Card",

    props: {
        image: String,
        title: String,
        subtitle: String,
        desc: String
    }
}
</script>

<style lang="scss" scoped>
.card {
    border-radius: 0;
    border: none;

    .card-thumb {
        overflow: hidden;

        img {
            width: 100%;
            transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transition: .3s;
            -webkit-transition: .3s;
            -moz-transition: .3s;
            -ms-transition: .3s;
            -o-transition: .3s;
        }
    }

    .card-body{
        padding: 28px 22px 31px 30px;

        h4 {
            font-size: 14px;
            color: $primary;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 15px;
            font-family: $body-font-family;
        }

        h2 {
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 600;
            color: $bg-color;
        }

        p {
            margin-bottom: 0;
            text-align: justify;
        }
    }

    &:hover img {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
    }
}
</style>