<template>
    <div class="services">
        <div class="container">
            <Title subject="Services" title="Nos services" subtitle="Des années d'expériences à votre service, pour vos logements !" outlined centered />
            <div class="services-box-container" :class="$mq">
                <div 
                    class="services-box text-center"
                    v-for="(item, idx) in services"
                    :key="idx"
                >  
                    <span :class="item.icon"></span>
                    <h3 v-html="item.title"></h3>
                    <h6>Démarche qualité en cours</h6>
                    <p v-html="item.desc"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Title from '@/components/Commons/Title'

export default {
    name: "Services",

    components: {
        Title
    },

    data() {
        return {
            services: [
                {
                    icon: "mdi icon mdi-water",
                    title: "Humidité",
                    desc: "Toujours dans un objectif de traitement contre les champigons lignivores, <b>Normandie Mérule</b> traite le mal à la racine en <b>solutionnant tous vos problèmes liés à l'humidité : remontées capillaires, condensation et infiltration</b>."
                },
                {
                    icon: "mdi icon mdi-weather-windy",
                    title: "Ventilation",
                    desc: "Experte dans le domaine, <b>Normandie Mérule</b> procède à l'<b>installation de système de ventilation, luttant contre la condensation</b> et donc contre les champignons lignivores comme <b>la mérule</b> ou <b>le coniophore</b>."
                },
                {
                    icon: "mdi icon mdi-home-roof",
                    title: "Démoussage",
                    desc: "Tous les toits ont besoin d'être entretenus régulièrement alors <b>Normandie Mérule intervient chez les particuliers et les professionnels</b> pour tous les travaux de démoussage, sur <b>tous les types de toiture</b>."
                },
                {
                    icon: "mdi icon mdi-mushroom",
                    title: "Traitement des champignons",
                    desc: "<b>Normandie Mérule</b> intervient dans <b>tous type de traitement contre les champigons lignivores dont la mérule</b>, du diagnostic à la réalisation, nous trouverons <b>une solution efficace et adaptée</b>."
                },
                {
                    icon: "mdi icon mdi-axe",
                    title: "Traitement <br> du bois",
                    desc: "Les <b>insectes xylophages</b> se nourrissent du bois qui compose la structure de nos maisons. <b>Normandie Mérule</b> vous propose le <b>traitement préventif et curatif de vos bois de charpente, colombages et solivages</b>."
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.services {
    margin-top: 60px;
    padding-top: 100px;
    padding-bottom: 60px;
    background-color: $grey50;
    color: $grey;

    .services-box-container {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-around;

        &.tablet, &.mobile {
            flex-direction: column;

            .services-box {
                width: 100%;
            }
        }

        .services-box {
            margin-top: 40px;
            width: 30%;
            background: #fff;
            padding: 50px;
            position: relative;
            transition: .3s;
            -webkit-transition: .3s;
            -moz-transition: .3s;
            -ms-transition: .3s;
            -o-transition: .3s;


            h3 {
                text-transform: uppercase;
            }

            h6 {
                font-style: italic;
            }

            p {
                font-size: 11pt;
            }

            .icon {
                font-size: 24pt;
                color: $primary;
            }

            &::before {
                position: absolute;
                bottom: 0px;
                width: 0%;
                height: 5px;
                content: "";
                right: 0;
                background: $primary;
                z-index: 2;
                transition: .3s;
                -webkit-transition: .3s;
                -moz-transition: .3s;
                -ms-transition: .3s;
                -o-transition: .3s;
            }

            &:hover {
                box-shadow: 0px 18px 38px 0px lighten($color: $primary, $amount: .3);

                &::before {
                    width: 100%;
                    right: auto;
                    left: 0;
                }
            }
        }
    }
}
</style>