<template>
    <header class="hero-banner" :class="$mq" :style="{ backgroundImage: `url('${image}')` }">
        <div class="overlay"></div>
        <div class="container">
            <div class="hero-content">
                <h1>Votre expert traitement de la mérule en Normandie !</h1>
                <p class="mb-5">
                    <b>Normandie Mérule</b> vous accompagne dans tout vos travaux de traitement de la mérule dans en <b>Normandie</b>, à <b>Caen</b> et même à <b>Rouen</b>. Nous intervenons en tant qu’entreprise artisanale du bâtiment avec des <b>équipements performants et professionnels</b> pour vous proposer un <b>travail de qualité aux finitions soignées</b>.
                </p>
                <a 
                    class="cta-btn"
                    v-scroll-to="{
                        el: '#contact',
                        duration: $mq === 'mobile' ? 300 : 200,
                        offset: $mq === 'mobile' ? -50 : -135
                    }"
                    href="#"
                >
                    CONTACTEZ NOUS
                </a>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: "HeroBanner",
    props: {
        image: String
    }
}
</script>

<style lang="scss" scoped>
    .hero-banner {
        background-size: cover;
        background-position: 50%;
        height: 100vh;
        position: relative;

        display: flex;
        align-items: center;
        justify-content: left;

        -webkit-transition: background-image 0.5s ease-in-out;
        transition: background-image 0.5s ease-in-out;

        .cta-btn {
            &:hover {
                text-decoration: none;
            }
        }

        .overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba(255, 255, 255, .5);
            z-index: 0;
        }

        .hero-content {
            max-width: 50%;
            padding: 40px;
            border-radius: 50px;
            position: relative;
            z-index: 1;
            color: $bg-color;
            
            h1 {
                color: $bg-color;
                font-weight: bold;
                text-transform: uppercase;
            }
        }

        &.tablet {
            .hero-content {
                margin-top: -100px;
                max-width: 100%;
            }
        }

        &.mobile {
            height: 130vh;
            padding-top: 200px;

            .overlay {
                height: 100vh;
            }

            h1 {
                font-size: 24pt;
            }

            .hero-content {
                max-width: 100%;
            }
        }
    }
</style>