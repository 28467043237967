<template>
    <div 
        class="title"
        :class="[
            outlined ? 'outlined' : '',
            contact ? 'contact-title' : '',
            centered ? 'centered' : '',
            about ? 'about-title' : '',
            $mq
        ]
    ">
        <div class="border-title">
            <h1>{{ subject }}</h1>
        </div>
        <div class="title">
            <h5>{{ title }}</h5>
            <h2>{{ subtitle }}</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: "Title",

    props: {
        subject: String,
        title: String,
        subtitle: String,
        outlined: {
            type: Boolean,
            default: false
        },
        contact: {
            type: Boolean,
            default: false
        },
        centered: {
            type: Boolean,
            default: false
        },
        about: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
.title {
    position: relative;

    .border-title {
        h1 {
            position: absolute;
            font-family: $body-font-family;
            font-size: 100px;
            text-transform: uppercase;
            line-height: 70px;
            color: transparent;
            left: 0;
            -webkit-text-stroke: 2px rgba(250, 250, 250, .2);
            top: -52px;
            margin-bottom: 0;
            z-index: -1;
        }
    }

    .title {
        h5 {
            font-size: 15px;
            text-transform: uppercase;
            color: $primary;
            font-weight: 700;
            margin-bottom: 22px;
            font-family: $body-font-family;
        }
    }

    &.outlined {
        h2, h5 {
            color: $grey;
        }
    }

    &.contact-title {
        h1 {
            z-index: 1;
        }

        h2, h5 {
            position: inherit;
            z-index: 2;
            color: $grey;
        }
    }

    &.centered {
        text-align: center;

        h1 {
            left: 50%;
            transform: translate(-50%);
        }
    }

    &.about-title {
        .border-title h1 {
            font-size: 70px;
            top: -45px;
        }
    }

    &.about-title.mobile {
        .border-title h1 {
            font-size: 48px;
            top: -40px;
        }
    }

    &.mobile {
        h1 {
            font-size: 70px;
        }
    }
}
</style>