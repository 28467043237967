<template>
    <div class="contact-form" :class="$mq">
        <transition name="fade" mode="out-in">
            <form ref="formContainer" v-if="submitStatus !== 'OK'">
                <div class="row">
                    <div class="form-group col-md-6">
                        <div class="text-box">
                            <span class="mdi icon mdi-account-outline"></span>
                            <input type="text" placeholder="Votre nom *" v-model="$v.message.name.$model" required> 
                        </div>
                        <transition name="fade" mode="out-in">
                            <small class="text-box-small form-text text-danger" v-if="$v.message.name.$error">Ce champ est requis.</small>
                        </transition>
                    </div>
                    <div class="form-group col-md-6">
                        <div class="text-box">
                            <span class="mdi icon mdi-email-outline"></span>
                            <input type="text" placeholder="Votre e-mail *" v-model.trim.lazy="$v.message.mail.$model" required> 
                        </div>
                        <transition name="fade" mode="out-in">
                            <small class="text-box-small form-text text-danger" v-if="$v.message.mail.$error">Ce champ est requis.</small>
                        </transition>
                    </div>
                </div>
                <div class="form-group">
                    <div class="text-box">
                        <span class="mdi icon mdi-arrow-right-bold-outline"></span>
                        <input type="text" placeholder="Sujet *" v-model="$v.message.subject.$model" required> 
                    </div>
                    <transition name="fade" mode="out-in">
                        <small class="text-box-small form-text text-danger" v-if="$v.message.subject.$error">Ce champ est requis.</small>
                    </transition>
                </div>
                <div class="form-group">
                    <div class="message-box">
                        <span class="mdi icon mdi-file-document-edit-outline"></span>
                        <textarea name="message" cols="30" rows="10" placeholder="Votre message *" v-model="$v.message.message.$model" required></textarea>
                    </div>
                    <transition name="fade" mode="out-in">
                        <small class="form-text text-danger" v-if="$v.message.message.$error">Ce champ est requis.</small>
                    </transition>
                </div>
                <div class="text-right">
                    <button class="cta-btn" type="submit" @click.prevent="send" :disabled="submitStatus === 'PENDING'">
                        <span class="mdi icon mdi-send-outline"></span> Envoyer
                    </button>
                </div>
            </form>

            <p class="mt-5 mb-0 text-center" v-else>
                Votre message a été envoyé à notre équipe avec succès !
            </p>
        </transition>
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
    name: "ContactForm",

    data() {
        return {
            message: {
                name: null,
                mail: null,
                subject: null,
                message: null
            },
            submitStatus: null
        }
    },

    validations: {
        message: {
            name: { required },
            mail: { required, email },
            subject: { required },
            message: { required }
        }
    },

    methods: {
        send() {
            this.$v.$touch()

            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                this.submitStatus = 'PENDING'

                let loader = this.$loading.show({
                    container: this.$refs.formContainer,
                    color: '#2c3e50',
                    loader: 'dots'
                });

                window.emailjs.send('smtp_normandie_merule', 'template_645ze9u', this.message)
                    .then(() => {
                        this.resetData()
                        this.submitStatus = 'OK'
                        loader.hide()   
                    })
                    .catch((e) => {
                        this.resetData()
                        this.submitStatus = 'ERROR'
                        loader.hide()
                    });

                setTimeout(() => {
                    this.resetData()
                    this.submitStatus = 'OK'
                    loader.hide()
                }, 5000)
            }
        },

        resetData() {
            this.message = {
                name: null,
                mail: null,
                subject: null,
                message: null
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.contact-form {
    position: relative;

    .text-box {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        span {
            font-size: 16pt;
            position: absolute;
            padding: 0 30px;
            color: $bg-color;
        }

        input {
            width: 100%;
            height: 70px;
            background: $grey90;
            padding: 10px 30px;
            padding-left: 65px;
            border: 1px solid transparent;
            transition: .3s;
            -webkit-transition: .3s;
            -moz-transition: .3s;
            -ms-transition: .3s;
            -o-transition: .3s;

            &:focus {
                border-color: $bg-color;
            }
        }
    }

    .message-box {
        position: relative;

        span {
            font-size: 16pt;
            position: absolute;
            padding: 15px 30px;
            color: $bg-color;
        }

        textarea {
            width: 100%;
            height: 140px;
            border: 0;
            background: $grey90;
            padding: 15px;
            padding-left: 65px;
            border: 1px solid transparent;
            transition: .3s;
            -webkit-transition: .3s;
            -moz-transition: .3s;
            -ms-transition: .3s;
            -o-transition: .3s;

            &:focus {
                border-color: $bg-color;
            }
        }
    }

    button {
        margin-top: 20px;
        cursor: pointer;
        border: 0;
    }

    .text-box-small {
        position: relative;
        top: -20px;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s !important;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>