<template>
    <div class="about" :class="$mq">
         <vue-tabs>
            <v-tab title="Champignons" icon="mdi icon mdi-mushroom">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about-left">
                            <Title subject="Champignons" title="Traitement des champignons" subtitle="Le spécialiste du traitement des champignons en Normandie !" about />
                            <p>La mérule est <b>le champignon lignivore le plus répandu en France et le plus virulent</b> : apparaissant sur le bois, elle se développe d'autant plus <b>en présence d'humidité</b>.</p>
                            <p>Chaque situation nécessitant un traitement adapté, <b>Normandie Mérule</b> vous accompagne <b>du diagnostic à la réalisation d'un traitement curatif</b> en travaillant sur les zones inféctés (dépose des revêtements, enduits, placos, etc.).</p>
                            <ul class="about-info mt-30 mb-30">
                                <li>
                                    <div class="about-info-title">
                                        <span><i class="fas fa-check"></i></span>
                                        <h5>Nous réalisons un diagnostic précis et clair.</h5>
                                    </div>
                                </li>
                                <li>
                                    <div class="about-info-title">
                                        <span><i class="fas fa-check"></i></span>
                                        <h5>Nous effectuons les travaux préparatoires.</h5>
                                    </div>
                                </li>
                                <li>
                                    <div class="about-info-title">
                                        <span><i class="fas fa-check"></i></span>
                                        <h5>Nous menons à bien les travaux de traitement.</h5>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <img src="@/assets/img/merule.jpg" alt="traitement merule">
                    </div>
                </div>
            </v-tab>

            <v-tab title="Savoir-faire" icon="mdi icon mdi-hammer-screwdriver">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about-left">
                            <Title subject="Savoir-faire" title="Notre savoir-faire" subtitle="Nos compétences sont multiples et sont mises à votre service !" about />
                            <p>Travailler avec <b>Normandie Mérule</b>, c'est avant tout pouvoir s'appuyer sur <b>des compétences et des connaissances fortes acquises depuis des dizaines d'années d'expérience</b> dans le traitement de l'habitat.</p>
                            <p>Du <b>traitement de la mérule</b> au démoussage, mais aussi bien d'autres domaines experts liés au traitement de l'habitat, <b>Normandie Mérule</b> est là pour <b>vous accompagner dans la réalisation de vos travaux</b>.</p>
                            <ul class="about-info mt-30 mb-30">
                                <li>
                                    <div class="about-info-title">
                                        <span><i class="fas fa-check"></i></span>
                                        <h5>Accompagner nos clients dans le traitement de leur habitat.</h5>
                                    </div>
                                </li>
                                <li>
                                    <div class="about-info-title">
                                        <span><i class="fas fa-check"></i></span>
                                        <h5>Répondre à vos besoins et vos exigences.</h5>
                                    </div>
                                </li>
                                <li>
                                    <div class="about-info-title">
                                        <span><i class="fas fa-check"></i></span>
                                        <h5>Sensibiliser aux bonnes pratiques.</h5>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <img src="@/assets/img/treat.jpg" alt="image_not_found">
                    </div>
                </div>
            </v-tab>

            <v-tab title="HUMIDITÉ" icon="mdi icon mdi-water">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about-left">
                            <Title subject="HUMIDITÉ" title="Traitement de l'humidité" subtitle="Votre expert en traitement de l'humidité en Normandie !" about />
                            <p><b>Normandie Mérule</b> intervient à votre domicile pour assurer <b>un diagnostic d'expert de l'humidité des sols et des murs</b> afin de vous proposer <b>les meilleures solutions pour votre habitation</b>.</p>
                            <p>Fort des ses années d'expériences, <b>Normandie Mérule</b> assure le <b>traitement de l’humidité par remontées capillaires, salpêtre, condensation, moisissure</b> en Normandie. <b>Normandie Mérule</b> met également à votre disposition des services pour le diagnostic d'humidité de votre logement.</p>
                            <ul class="about-info mt-30 mb-30">
                                <li>
                                    <div class="about-info-title">
                                        <span><i class="fas fa-check"></i></span>
                                        <h5>Diagnostic des symptômes de l'humidité</h5>
                                    </div>
                                </li>
                                <li>
                                    <div class="about-info-title">
                                        <span><i class="fas fa-check"></i></span>
                                        <h5>Processus de déshumidification</h5>
                                    </div>
                                </li>
                                <li>
                                    <div class="about-info-title">
                                        <span><i class="fas fa-check"></i></span>
                                        <h5>Traitement des remontées capillaires et infiltrations</h5>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <img src="@/assets/img/humidite/1.jpg" alt="image_not_found">
                    </div>
                </div>
            </v-tab>
        </vue-tabs>
    </div>
</template>

<script>
import Title from '@/components/Commons/Title'

export default {
    name: "About",

    components: {
        Title
    }
}
</script>

<style lang="scss">
.about {
    .nav-tabs {
        display: flex;
        justify-content: space-between;
        border: none;

        .tab {
            flex-grow: 1;
            cursor: pointer;
            padding: 16px 8px;
            text-align: center;
            color: $secondary;

            a {
                color: inherit;

                &:hover {
                    text-decoration: none;
                }
            }

            &.active {
                border-bottom: 4px solid $primary;
                box-shadow: 0px 13px 40px 0px rgba(126, 154, 180, 0.3);
                color: $primary;
            }

            .title {
                font-family: $heading-font-family;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 24pt;
            }
        }
    }

    .tab-content {
        margin-top: 120px;

        img {
            max-width: 100%;
        }

        .tab-container {
            animation: fadeIn 0.5s;
        }
    }

    &.mobile {
        .tab-content {
            margin-top: 60px;
        }

        .nav-tabs {
            margin-top: 20px;
        }
    }
}
</style>