<template>
    <div class="values">
        <Title subject="Valeurs" title="Nos valeurs" subtitle="Normandie Mérule, un véritable partenaire de confiance." centered />
        <div class="row mt-5">
            <div
                class="col-md-4 mb-md-0 mb-5"
                v-for="(item, idx) in valeurs"
                :key="idx"
            >
                <Card :image="item.image" :title="item.title" :subtitle="item.subtitle" :desc="item.desc" />
            </div>
        </div>
    </div> 
</template>

<script>
import Title from '@/components/Commons/Title'
import Card from '@/components/Commons/Card'

export default {
    name: 'Values',

    components: {
        Title,
        Card
    },

    data() {
        return {
            valeurs: [
                {
                    image: require('../../assets/img/values/1.png'),
                    title: "QUALITÉ",
                    subtitle: "Proposer des solitions adaptées",
                    desc: "Normandie Mérule s'appuie sur sa riche expérience dans le secteur pour vous proposer une solution efficace, précise et durable."
                },
                {
                    image: require('../../assets/img/values/2.png'),
                    title: "CONFIANCE",
                    subtitle: "Établir une vraie transparence",
                    desc: "Ciment de toute bonne relation, la confiance que nous accordent nos clients est un moteur chez Normandie Mérule."
                },
                {
                    image: require('../../assets/img/values/3.png'),
                    title: "ENGAGEMENT",
                    subtitle: "Respecter nos garanties",
                    desc: "Notre objectif premier chez Normandie Mérule est de réaliser vos travaux dans la maîtrise de votre budget, des délais et de la qualité."
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.values {
    margin: 100px 0;
}
</style>