<template>
	<div class="home">
		<HeroBanner :image="currentImage.src" />
		<section class="container" id="about">
			<About />
		</section>
		<Services id="services" v-animate="'fade-in'" />
		<section class="container" id="values">
			<Values v-animate="'fade-in'" />
		</section>
		<Contact id="contact" v-animate="'fade-in'" />
	</div>
</template>

<script>
import HeroBanner from '@/components/Banner/HeroBanner'
import About from '@/components/Home/About'
import Services from '@/components/Home/Services.vue'
import Values from '@/components/Home/Values.vue'
import Contact from '@/components/Home/Contact.vue'

export default {
	name: 'Home',

	data() {
		return {
			bgImages: [
				{
					src: require('../assets/img/bg/bg-1.jpg'),
					alt: ''
				},
				{
					src: require('../assets/img/bg/bg-2.jpg'),
					alt: ''
				},
				{
					src: require('../assets/img/bg/bg-3.jpg'),
					alt: ''
				}
			],
			currentImage: null
		}
	},

	components: {
		HeroBanner,
		About,
		Services,
		Values,
		Contact
	},

	created() {
		this.currentImage = this.bgImages[0]

		setInterval(() => { 
			this.currentImage = this.bgImages[Math.floor(Math.random() * this.bgImages.length)]
		}, 7000)
	}
}
</script>

<style lang="scss" scoped>
.home {
	margin-top: -165px;
}
</style>