<template>
    <div class="contact" :class="$mq">
        <div class="container">
            <div class="row mt-4 justify-content-between">
                <div class="col-md-5 col-12">  
                    <div class="contact-left">
                        <div class="contact-thumb-left">
                            <img src="@/assets/img/contact/1.jpg" alt="worker">
                            <div class="contact-shape"></div>
                        </div>
                        <div class="contact-thumb-right">
                            <img src="@/assets/img/contact/2.jpg" alt="image_not_found">
                        </div>
                    </div>
                </div>
                <div class="col-md-7 col-12 mt-md-0 mt-4">  
                    <Title subject="Contact" title="Contactez-nous" subtitle="Le devis est gratuit !" contact />
                    <ContactForm />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Title from '@/components/Commons/Title'
import ContactForm from '@/components/Forms/ContactForm'

export default {
    name: "Contact",

    components: {
        Title,
        ContactForm
    }
}
</script>

<style lang="scss" scoped>
.contact {
    padding-top: 100px;
    padding-bottom: 120px;
    background-color: $primary;
    color: $grey;

    .contact-left {
        position: relative;

        .contact-thumb-left {
            position: relative;

            img {
                width: 75%;
            }

            .contact-shape {
                border: 30px solid $white;
                height: 80%;
                position: absolute;
                top: 38px;
                width: 53%;
                left: 51px;
                opacity: 25%;
            }
        }

        .contact-thumb-right {
            position: absolute;
            top: 215px;
            right: 0;
        }
    }

    &.mobile {
        padding-top: 50px;
        padding-bottom: 40px;

        .contact-left {
            margin-bottom: 300px;
        }
    }
}
</style>